<template>
    <div ref="container"></div>
  </template>
  
  <script>
 import * as THREE from 'three';
 import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";  
 import { USDZLoader } from 'three-usdz-loader';
 import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";


  
  export default {
    props: {
      data3dImage: Object
    },
    
    data() {
      return {
      //   scene: null,
      // camera: null,
      // renderer: null,
      }
    },
  
    methods: {
      async initThree() {
        // Создание сцены
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xffffff);
        this.meshGroup;

        // Setup light
        const ambiantLight = new THREE.AmbientLight(0x111111);
        ambiantLight.intensity = 1;
        this.scene.add(ambiantLight);

        // // Добавление света
        // const light = new THREE.DirectionalLight(0xffffff, 1);
        // light.position.set(1, 1, 1).normalize();
        // this.scene.add(light);

        // Setup camera
        this.camera = new THREE.PerspectiveCamera(
          27,
          (this.$refs.container.clientWidth || 600) / (this.$refs.container.clientHeight || 600),
          1,
          3500
        );
        this.camera.position.z = 7;
        this.camera.position.y = 7;
        this.camera.position.x = 0;
        window.tt = this.camera.position;

        // // Создание камеры
        // this.camera = new THREE.PerspectiveCamera(
        //   75,
        //   this.$refs.container.clientWidth / this.$refs.container.clientHeight,
        //   0.1,
        //   1000
        // );
        // this.camera.position.z = 5;

        // // Создание рендерера
        // this.renderer = new THREE.WebGLRenderer();
        // this.renderer.setSize(this.$refs.container.clientWidth || 300, this.$refs.container.clientHeight || 300);
        // this.$refs.container.appendChild(this.renderer.domElement);

        // Setup main scene
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio((this.$refs.container.clientWidth || 600) / (this.$refs.container.clientHeight || 600));
        this.renderer.setSize(this.$refs.container.clientWidth || 600, this.$refs.container.clientHeight || 600);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.shadowMap.enabled = false;
        this.renderer.shadowMap.type = THREE.VSMShadowMap;

        if (!window.envMap) {
          // Setup cubemap for reflection
          await new Promise((resolve) => {
            const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            pmremGenerator.compileCubemapShader();
            new RGBELoader().load(
              "studio_country_hall_1k.hdr",
              (texture) => {
                const hdrRenderTarget = pmremGenerator.fromEquirectangular(texture);

                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.needsUpdate = true;
                // window.envMap = hdrRenderTarget.texture;
                this.scene.environment = hdrRenderTarget.texture;
                hdrRenderTarget.texture.colorSpace = THREE.LinearSRGBColorSpace;
                resolve(true);
              }
            );
          });
        }

        //Add the canvas to the document
        this.$refs.container.appendChild(this.renderer.domElement);
        // this.threeContainer.appendChild(this.renderer.domElement);

        // Setup navigation
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.update();

        // Setup main animation update loop
        this.animate();

        // Создание загрузчика и загрузка модели
        const loader = new USDZLoader('/wasm');
        this.meshGroup = new THREE.Group();
        this.scene.add(this.meshGroup);

        // Предположим, что у вас есть файл модели в вашем проекте
        // const file = this.data3dImage.image_url
        const file = await fetch(this.data3dImage.image_url);
        const blob = await file.blob();
        const fileFromBlob = new File([blob], this.data3dImage.image_url.split('/').pop());
      //   require('@/assets/ar/object1.usdz');  // Замените на реальный путь к вашему файлу
        await loader.loadFile(fileFromBlob, this.meshGroup);

        // // Начало рендера
        // this.animate();
        this.fitCamera(this.camera, this.controls, [this.meshGroup]);
      },
      animate() {
        requestAnimationFrame(this.animate);

        if (this.meshGroup) {
          let time = Date.now() * 0.001;
          this.meshGroup.rotation.y = time;
          // this.meshGroup.rotation.z = 0.5 * ( 1 +  Math.sin( time ) );
        }

        this.renderer.render(this.scene, this.camera);
      },

      fitCamera(
        camera,
        controls,
        selection,
        fitOffset = 2.0
      ) {
        const cam = camera;
        const size = new THREE.Vector3();
        const center = new THREE.Vector3();
        const box = new THREE.Box3();

        box.makeEmpty();
        for (const object of selection) {
          box.expandByObject(object);
        }

        box.getSize(size);
        box.getCenter(center);

        const maxSize = Math.max(size.x, size.y, size.z);
        const fitHeightDistance =
          maxSize / (2 * Math.atan((Math.PI * cam.fov) / 360));
        const fitWidthDistance = fitHeightDistance / cam.aspect;
        const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

        const direction = controls.target
          .clone()
          .sub(cam.position)
          .normalize()
          .multiplyScalar(distance);

        controls.maxDistance = distance * 10;
        controls.target.copy(center);

        cam.near = distance / 100;
        cam.far = distance * 100;
        cam.updateProjectionMatrix();

        camera.position.copy(controls.target).sub(direction);

        controls.update();
      }
    },
  
    mounted() {
      console.log('Загрузка модели:', this.data3dImage.image_url);



      this.initThree();
      window.addEventListener('resize', this.onWindowResize);
    }
  };
  </script>
  
  <style>
  body {
    margin: 0;
    overflow: hidden;
  }
  </style>
  